.popup-card {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9000;
    background-color: transparent;
    /*padding: 20px;*/
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
    border-radius: 8px;
    max-height: 70%; /*Limit height to viewport*/
    overflow-y: scroll; /*Enable vertical scrolling*/
    overflow-x: hidden; /*Prevent horizontal scrolling if needed*/
    /*mask-image: linear-gradient(to bottom, transparent, black 8%, black 80%); !* Create fade effect *!*/


    /* Hide scrollbar for Webkit-based browsers */
    -webkit-overflow-scrolling: touch;
    /*-webkit-mask-image: linear-gradient(to bottom, transparent, black 20%, black 80%, transparent); !* For WebKit-based browsers *!*/
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.popup-card::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Edge */
}

.popup-card-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 8999;
    background-color: rgba(0, 0, 0, 0.4); /* Dimming the background */
    display: flex;
    justify-content: center;
    align-items: center;
}

.markdown-content {
    font-family: monospace; /* For a code-like font*/
    white-space: normal; /* Preserve formatting and line breaks */
    /*background-color: #f5f5f5; !*Light gray background for readability *!*/
    padding: 15px;
    border-radius: 8px;
    /*overflow-y: auto;*/
    overflow-x: hidden; /*Handle wide content by allowing horizontal scrolling */
    font-family: Arial, sans-serif;
    line-height: 1.6;

    h1, h2, h3, h4 {
        margin-top: 5px;
    }

    p {
        margin-bottom: 5px;
    }

    a {
        color: #007bff;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    ul {
        padding-left: 20px;

        li {
            margin-bottom: 10px;
        }
    }

    code {
        background-color: #f4f4f4;
        padding: 2px 4px;
        border-radius: 4px;
    }
}

.img {
    width: 2px;
    height: 2px;
}

.update-card {
    z-index: 9000;
}

.close-button {
    position: absolute;
    /*top: 10px;*/
    /*right: 10px;*/
    cursor: pointer;
    z-index: 9000;
}
