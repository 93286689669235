@import '../../public/assets/sass/custom_colors';

html {
  overflow: hidden;
}

.dev {
  pointer-events: none;
  position: fixed;
  top: 10px;
  left: -30px;
  z-index: 10000;
  height: 100px;
  text-align: center;
}

.bandarole {
  margin: auto;
  display: inline-block;
  font-size: 12px;
  width: 100px;
  text-align: center;
  color: white;
  transform: rotate(-45deg);
  border: 2px solid #fff;
  background: red;
}

//
//* {
//  font-family: "Font Awesome 5", serif;
//}

#root {
  .layout-breadcrumb-container {
    //margin-bottom: 65px;
    position: fixed;
    z-index: 90;

    .layout-breadcrumb-left-items {
      //position: fixed;
      top: 0;
      //margin: 1rem 2rem;
      padding: unset;
      margin: 0 1rem 0 1rem;
      z-index: 99;
    }

    .layout-breadcrumb-right-items {
      height: 80px;
      position: fixed;
      top: 0;
      right: 0;
      margin: 1rem;
      padding: 0;
      z-index: 99;

      .card {
        margin: 0;
      }
    }
  }

  .layout-topbar {
    //background-color: $DarkBlue;
    //pointer-events: none;
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    width: 100%;
    height: 100px;
    display: inherit;
    z-index: 90;
    pointer-events: none;
  }

  .layout-main-content {
    position: relative;
    margin-top: 100px;
    overflow: auto;

    &.geomonitoring {
      margin-top: 0;
      padding: 0;

      .ol-zoom {
        position: absolute;
        top: 105px;
        left: 14px;
      }

      .ctrl-map {
        position: absolute;
        top: 190px;
        left: 14px;
      }

      .toolkit-ctrl-map {
        position: absolute;
        overflow: hidden;
        top: 230px;
        left: 14px;
        z-index: 100;
        display: flex;
        max-width: 0;
        padding-left: 0 !important;
        padding-right: 0 !important;
        transition: all 0.3s ease-in-out;

        &.open {
          max-width: 500px;
          padding-left: 5px !important;
          padding-right: 5px !important;
        }
      }
    }
  }

  .p-dataview.p-component.p-dataview-list {
    overflow: auto;
    position: absolute;
    top: 10px;
    right: 16px;
    left: 16px;
    bottom: 100px;
  }

  .p-dataview.p-component.p-dataview-grid {
    overflow: auto;
    position: absolute;
    top: 10px;
    right: 16px;
    left: 16px;
    bottom: 60px;
  }

  .project-overview {
    overflow: hidden;
    position: absolute;
    left: 15px;
    right: 15px;
    top: 15px;
    bottom: 15px;

    //@media only screen and (max-width: 600px) {
    //  .p-dataview {
    //    margin-top: 200px;
    //  }
    //}
  }

  .p-link {
    border: none;
  }

  .layout-inline-menu-action {
    padding-left: 0;
  }

  .user-icon {
    //padding-left: 1.5rem;
    margin-left: 0.7rem;
    font-size: 1.5rem;
  }

  .menu-logo {
    position: absolute;
    left: 0;
    padding-left: 10px;
    padding-bottom: 40px !important;
    transition: left 0.2s ease-in-out;
    width: 100%;
  }

  .menu-pin {
    position: absolute;
    top: 2px;
    right: 2px;
  }

  .safeguard-logo {
    border-radius: 0;
    width: 260px;
    position: absolute;
    top: 60px;
    left: 10px;
    transition: left 0.2s ease-in-out;

    &.hide {
      left: -250px;
      transition: left 0.2s ease-in-out;
    }

    img {
      width: 195px;
      height: auto !important;
    }
  }

  .layout-menu {
    padding: 0;
    width: 100%;
    margin: auto;
  }

  .layout-menu-container {
    margin-top: 105px;
  }

  .layout-menu-wrapper .layout-menu-container .layout-menu > li:first-child {
    margin-top: 0;
  }

  .layout-wrapper.layout-sidebar .layout-menu-wrapper .layout-menu-container .layout-menu li > a > .p-badge {
    position: absolute;
    left: 30px;
    visibility: visible;
  }

  .layout-wrapper.layout-sidebar .layout-menu-wrapper.layout-sidebar-active .layout-menu-container .layout-menu li > a > .p-badge {
    position: inherit;
    left: inherit;
    top: inherit;
  }

  .layout-inline-menu .layout-inline-menu-action-panel {
    padding: 0;
  }

  .project-card {
    position: relative;
    padding-bottom: 10px;

    .product-grid-item-content {
      overflow: hidden;
    }

    .project-card-img {
      height: 60px !important;
      border-radius: 10px;
      overflow: hidden;
      margin: auto;
      padding: 5px;

      img {
        max-height: 45px !important;
        object-fit: contain;
        height: 100%;
      }
    }

    .project-number {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      padding-left: 10px;
      padding-bottom: 3px;
    }
  }

  .no-field-margin {
    .field {
      margin: 0;
    }
  }

  .iframe-wrapper {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
  }

  .project-edit-form.project-group-overview .project-group-list {
    max-height: 190px;
    overflow: auto;
  }

  .p-inputnumber-input {
    width: 100%;
  }
}

.input-label {
  margin-bottom: 0 !important;
  //font-size: 1.2rem;
}

.custom-inputfield {
  margin-top: 0 !important;
}

.p-datatable-scrollable .p-datatable-tbody > tr > td {
  display: block;
}

.expander-column {
  display: inline !important;
  flex: none !important;
  width: 3em;
}

.selection-column {
  max-width: 3em;
}

.cell-function {
  &:hover {
    color: darken(white, 30%);
  }
}

.wait-animation {
  position: absolute;
  top: 500px;
  left: 500px;
  z-index: 9;
  width: 200px;
  height: 200px;

  .w-all {
    width: 28px;
    height: 28px;
    padding: 0;
    float: left;
    background-color: rgb(250, 250, 250);
    -moz-border-radius: 30px;
    -webkit-border-bottom: 30px;
    -khtml-border-bottom-: 30px;
    border-radius: 30px;
    border: 1px solid rgba(250, 250, 250, 0.5);
  }

  .w-top {
    margin: 0 70px;
  }

  .w-top-left {
    margin: -5px 40px 10px 15px;
  }

  .w-top-right {
    margin: -5px 15px 10px 40px;
  }

  .w-left {
    margin: 10px 55px 10px 0;
  }

  .w-right {
    margin: 10px 0 10px 55px;
  }

  .w-bottom-left {
    margin: 5px 40px -10px 20px;
  }

  .w-bottom-right {
    margin: 5px 20px -10px 30px;
  }

  .w-bottom {
    margin: -00px 70px 0px 70px;
  }
}

.scroll-card-main-content {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  margin: 0 !important;
}

.wizard-scroll-card-content {
  overflow: auto;
  position: absolute;
  top: 160px;
  left: 24px;
  right: 24px;
  bottom: 150px;
  margin: 0 !important;
}

.wizard-button-card {
  position: absolute;
  left: 24px;
  right: 24px;
  bottom: 50px;
}

.hide-x {
  .p-dialog-header-icon.p-dialog-header-close.p-link {
    display: none;
  }
}

.scanner-buttons {
  position: absolute;
}

.grafana-buttons {
  position: absolute;

  /*! CSS Used from: https://grafana.dmt.de/public/build/grafana.light.1bdf8d32cc2516e2475b.css */
  svg:not(:root) {
    overflow: hidden;
  }

  button {
    color: inherit;
    font: inherit;
    margin: 0;
    box-shadow: none !important;
  }

  button {
    overflow: visible;
  }

  button {
    text-transform: none;
  }

  button {
    -webkit-appearance: button;
    cursor: pointer;
  }

  *, :after, :before {
    box-sizing: inherit;
  }

  button {
    touch-action: manipulation;
  }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  button {
    border-radius: 0;
    line-height: inherit;
    margin: 0;
  }

  .no-overlay-scrollbar ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  .no-overlay-scrollbar ::-webkit-scrollbar:hover {
    height: 8px;
  }

  .no-overlay-scrollbar ::-webkit-scrollbar-track-piece {
    background-color: #0000;
  }

  /*! CSS Used from: Embedded */
  .css-wf08df-Icon {
    display: inline-block;
    line-height: 0;
  }

  .css-eyx4do {
    vertical-align: middle;
    display: inline-block;
    fill: currentcolor;
  }

  .p-button {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 32px;
    padding: 0px 8px;
    line-height: 30px;
    font-weight: 500;
    border: 1px solid rgba(36, 41, 46, 0.12);
    white-space: nowrap;
    transition: background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgba(36, 41, 46, 0.75);
    background-color: rgb(255, 255, 255);
  }

  .p-button:focus {
    outline: transparent dotted 2px;
    outline-offset: 2px;
    box-shadow: rgb(244, 245, 245) 0px 0px 0px 2px, rgb(56, 113, 220) 0px 0px 0px 4px;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-duration: 0.2s;
    transition-property: outline, outline-offset, box-shadow;
    z-index: 1;
  }

  .p-button:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
  }

  .p-button:hover {
    box-shadow: rgba(24, 26, 27, 0.2) 0px 1px 2px;
  }

  .p-button:disabled {
    cursor: not-allowed;
    opacity: 0.38;
    background: rgba(36, 41, 46, 0.04);
    box-shadow: none;
  }

  .p-button:disabled:hover {
    color: rgba(36, 41, 46, 0.5);
    background: rgba(36, 41, 46, 0.04);
    box-shadow: none;
  }

  .p-button:hover {
    color: rgb(36, 41, 46);
    background: rgb(244, 245, 245);
  }

  .css-1kl6l1j > button:not(:first-child) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  /*! CSS Used from: Embedded */
  button {
    letter-spacing: 0.01071em;
  }

  button:focus {
    outline: none;
  }

  button {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.57143;
  }

  button {
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }
}

.wiki-buttons {
  position: absolute;
  top: 72px;

  /*! CSS Used from: https://grafana.dmt.de/public/build/grafana.light.1bdf8d32cc2516e2475b.css */
  svg:not(:root) {
    overflow: hidden;
  }

  button {
    color: inherit;
    font: inherit;
    margin: 0;
    box-shadow: none !important;
  }

  button {
    overflow: visible;
  }

  button {
    text-transform: none;
  }

  button {
    -webkit-appearance: button;
    cursor: pointer;
  }

  *, :after, :before {
    box-sizing: inherit;
  }

  button {
    touch-action: manipulation;
  }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  button {
    border-radius: 0;
    line-height: inherit;
    margin: 0;
  }

  .no-overlay-scrollbar ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  .no-overlay-scrollbar ::-webkit-scrollbar:hover {
    height: 8px;
  }

  .no-overlay-scrollbar ::-webkit-scrollbar-track-piece {
    background-color: #0000;
  }

  /*! CSS Used from: Embedded */
  .css-wf08df-Icon {
    display: inline-block;
    line-height: 0;
  }

  .css-eyx4do {
    vertical-align: middle;
    display: inline-block;
    fill: currentcolor;
  }

  .p-button {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 32px;
    padding: 0px 8px;
    line-height: 30px;
    font-weight: 500;
    border: 1px solid rgba(36, 41, 46, 0.12);
    white-space: nowrap;
    transition: background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgba(36, 41, 46, 0.75);
    background-color: rgb(255, 255, 255);
  }

  .p-button:focus {
    outline: transparent dotted 2px;
    outline-offset: 2px;
    box-shadow: rgb(244, 245, 245) 0px 0px 0px 2px, rgb(56, 113, 220) 0px 0px 0px 4px;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-duration: 0.2s;
    transition-property: outline, outline-offset, box-shadow;
    z-index: 1;
  }

  .p-button:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
  }

  .p-button:hover {
    box-shadow: rgba(24, 26, 27, 0.2) 0px 1px 2px;
  }

  .p-button:disabled {
    cursor: not-allowed;
    opacity: 0.38;
    background: rgba(36, 41, 46, 0.04);
    box-shadow: none;
  }

  .p-button:disabled:hover {
    color: rgba(36, 41, 46, 0.5);
    background: rgba(36, 41, 46, 0.04);
    box-shadow: none;
  }

  .p-button:hover {
    color: rgb(36, 41, 46);
    background: rgb(244, 245, 245);
  }

  .css-1kl6l1j > button:not(:first-child) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  /*! CSS Used from: Embedded */
  button {
    letter-spacing: 0.01071em;
  }

  button:focus {
    outline: none;
  }

  button {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.57143;
  }

  button {
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }
}

.nextcloud-buttons {
  position: absolute;
  left: 25px;
  top: 21px;
  z-index: 100;
  //background-color: #0082c8;
  padding: 0 3px 3px 0;

  .button-fullscreen {
    float: right;
    z-index: 100;
    margin-left: 0px !important
  }

  .button-new-tab {
    float: right;
    z-index: 100;
    margin-right: 0px !important
  }

  /*! CSS Used from: https://grafana.dmt.de/public/build/grafana.light.1bdf8d32cc2516e2475b.css */
  svg:not(:root) {
    overflow: hidden;
  }

  button {
    color: inherit;
    font: inherit;
    box-shadow: none !important;
  }

  button {
    overflow: visible;
  }

  button {
    text-transform: none;
  }

  button {
    -webkit-appearance: button;
    cursor: pointer;
  }

  *, :after, :before {
    box-sizing: inherit;
  }

  button {
    touch-action: manipulation;
  }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
  }

  button {
    border-radius: 0;
    line-height: inherit;
  }

  .no-overlay-scrollbar ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  .no-overlay-scrollbar ::-webkit-scrollbar:hover {
    height: 8px;
  }

  .no-overlay-scrollbar ::-webkit-scrollbar-track-piece {
    background-color: #0000;
  }

  /*! CSS Used from: Embedded */
  .css-wf08df-Icon {
    display: inline-block;
    line-height: 0;
  }

  .css-eyx4do {
    vertical-align: middle;
    display: inline-block;
    fill: currentcolor;
  }

  .p-button {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 32px;
    padding: 0px 8px;
    line-height: 30px;
    font-weight: 500;
    border: 1px solid rgba(36, 41, 46, 0.12);
    white-space: nowrap;
    transition: background 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgba(36, 41, 46, 0.75);
    background-color: rgb(255, 255, 255);
  }

  .p-button:focus {
    outline: transparent dotted 2px;
    outline-offset: 2px;
    box-shadow: rgb(244, 245, 245) 0px 0px 0px 2px, rgb(56, 113, 220) 0px 0px 0px 4px;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
    transition-duration: 0.2s;
    transition-property: outline, outline-offset, box-shadow;
    z-index: 1;
  }

  .p-button:focus:not(:focus-visible) {
    outline: none;
    box-shadow: none;
  }

  .p-button:hover {
    box-shadow: rgba(24, 26, 27, 0.2) 0px 1px 2px;
  }

  .p-button:disabled {
    cursor: not-allowed;
    opacity: 0.38;
    background: rgba(36, 41, 46, 0.04);
    box-shadow: none;
  }

  .p-button:disabled:hover {
    color: rgba(36, 41, 46, 0.5);
    background: rgba(36, 41, 46, 0.04);
    box-shadow: none;
  }

  .p-button:hover {
    color: rgb(36, 41, 46);
    background: rgb(244, 245, 245);
  }

  .css-1kl6l1j > button:not(:first-child) {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  /*! CSS Used from: Embedded */
  button {
    letter-spacing: 0.01071em;
  }

  button:focus {
    outline: none;
  }

  button {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.57143;
  }

  button {
    font-family: Roboto, Helvetica, Arial, sans-serif;
  }
}


.alarm_alarm, .alarm_ok, .alarm_warning, .alarm_timeout {
  background-color: red;
  display: block;
  width: auto;
  height: auto;
  padding: 10px;
  color: #2E323F;
}

.alarm_alarm {
  background-color: #FC6161;
  color: #FFFFFF;
}

.alarm_ok {
  background-color: #8CF000;
}

.alarm_warning, .alarm_timeout {
  background-color: #EEE500;
  color: #2E323F;
}

@keyframes p-progress-spinner-color {
  0% {
    stroke: #001ed2;
  }
  100% {
    stroke: #00003c;
  }
}

#id_d {
  animation: loading-bar-morph 1.5s linear .0s infinite;
  transform-origin: center
}

#id_m {
  animation: loading-bar-morph 1.5s linear .2s infinite;
  transform-origin: center
}

#id_t {
  animation: loading-bar-morph 1.5s linear .4s infinite;
  transform-origin: center;
}

@keyframes loading-bar-morph {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}

.p-tabview-nav-next {
  margin-left: 20px;
}

.tooltipHover {
  background-color: rgb(0, 247, 255);
}
