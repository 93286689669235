.role-permission-div {
  transition: background-color 0.3s ease;
  padding: 4px 0 4px 0;
  border-radius: 5px;
}

.role-permission-header-div  {
  padding: 2px 0px 2px 0px;
  border-radius: 5px;
}
