.chip-firstElement {
  position: relative;
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 5px 12px 8px;

  &.deletable {
    padding-right: 8px;
  }
}

.chip-label {
  font-weight: 500;

  &.editable {
    cursor: pointer;
  }

  &.deleted {
    //text-decoration: line-through;
    opacity: 0.5
  }

  &.underline {
    text-decoration: underline;
  }
}

.chip-icon {
  font-size: 16px;
  margin-top: 5px;
  margin-right: 8px;
}

;
.chip-iconButtons {
  display: flex;
}

.chip-iconButton {
  font-size: 20px;
  margin-top: 2px;
  margin-left: 8px;
  cursor: pointer;
  transition: all 0.3s linear;

  &.delete {
    opacity: 0;
    transform: rotate(180deg) scale(.5);
  }

  &.undo {
    position: absolute;
    opacity: 0;
    transform: rotate(180deg) scale(.5);
  }

  &.visible {
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }
}
