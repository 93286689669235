@import '/node_modules/primeflex/primeflex.css';


.card-example {
    width: 145px;
    height: 110px;
    border-radius: 20px;
    margin: 5px;
    padding: 10px;
    color: white;
    box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.4);
    position: relative;
    padding-bottom: 0;
    border-width: 2px;
    border-style: solid;


    .pi.pi-bookmark-fill::before {
      fill: none;
      stroke: black;
      stroke-width: 1px;
      stroke-linejoin: round;
    }

    .subline {
      font-size: 0.8rem;
    }

    .card-menu {
      cursor: pointer;
      visibility: hidden;
      position: absolute;
      top: 5px;
      right: 15px;
    }

    .card-top-line {
      height: 18px;
    }

    &:hover {
      .card-menu {
        visibility: visible;
      }

      .bookmark-white {
        visibility: visible !important;
      }
    }

    .bookmark-content {
      cursor: pointer;
      width: auto;
      position: absolute;
      top: -8px;
      left: 4px;

      .bookmark-outline {
        color: white;
        position: absolute;
        font-size: 1.3rem;
        top: 7px;
        left: 5px
      }

      .bookmark-color {
        position: absolute;
        left: 12px
      }

      .bookmark-white {
        position: absolute;
        visibility: hidden;
        left: 12px

      }
    }

    .arrow-up {
      position: relative;
      top: 3px;
      padding: 0;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 14px solid yellow;

      i {
        position: absolute;
        left: -7px;
        top: 0.8px;
      }
    }

    .alarm-circle {
      border-radius: 20px;
      background-color: yellow;
      width: 22px;
      height: 18px;
      margin: auto;
      padding: 0;
      text-align: center;
    }

    .offline-border {
      overflow: hidden;
      position: relative;
      border: 1px solid black;
      background-color: limegreen;
      border-radius: 20px;
      height: 10px;
      width: 100%;
      margin: auto;
      z-index: 10;

      .offline {
        position: absolute;
        right: 0;
        background-color: darkred;
        height: 10px;
        z-index: 1;
      }
    }
  }
  .green-circle {
    width: 30px;
    height: 30px;
    line-height: 28px;
    border-radius: 28%;
    font-size: 16px;
    color: #fff;
    text-align: center;
    background: green;
  }

  .orange-circle {
    width: 30px;
    height: 30px;
    line-height: 28px;
    border-radius: 28%;
    font-size: 16px;
    color: #fff;
    text-align: center;
    background: orange;
  }

  .red-circle {
    width: 30px;
    height: 30px;
    line-height: 28px;
    border-radius: 28%;
    font-size: 16px;
    color: #fff;
    text-align: center;
    background: red;
  }
  .plant-container {
    margin: 0 0 0 auto;
  }

  .add-new-plant{
    font-size: 18px;
  }
  .add-new-plant:hover{
    cursor: pointer;
  }

  .plants-card{
    top: 5px !important;
  }

  .green-container:hover{
    cursor: pointer;
  }

  .detail-card {
    height: 100%;
    width: 100%;
  }

  .card-content {
    text-align: left;
    margin-left: 10px;
    font-size: 14px;
  }
  .information-card{
    text-align: left;
    margin-left: 10px;
    font-size: 15px;
  }
  .img-fluid{
    max-width: 100% !important;
    height: auto;
  }

  .on-status{
    color: green;
    font-weight: 600
  }

  .off-status{
    color: red;
    font-weight: 600;
  }

  .card-details:hover{
    cursor: pointer;
    background: #f3f3f3;
    transform: scale(1.05);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
  }

  .image-delete{
    color: red;
    float: right;
    cursor: pointer;
  }

  .upload-image{
    color: #5B5B5D;
  }

  .upload-image{
    font-weight: 600;
  }

  .upload-image:hover{
    cursor: pointer;
    color: #a99da1;
    font-weight: 600;
  }

  .p-bold{
    font-weight: 800 !important;
  }

  .delete-image{
    margin-left: 10px;
    color: red;
  }

  .delete-image:hover{
  cursor: pointer;
  font-weight: 600;
  }

  .checkDiv{
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    height: 390px;
    background-position: center;
    background-color: black;
  }

  .imageSpan{
    height: inherit;
  }
  .imageDiv{
    margin: auto;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .pump-image{
    width: 100%;
    height: 100%;
  }
  .editImage{
    cursor: pointer;
    color:white;
    position: absolute;
    top: 3px !important;
    right: 0 !important;
    display: none;
    z-index: 999;
  }
  .slider-cover{
    position: relative;
  }
  .slider-cover:hover .editImage{
  display: block;
  }
  .carousel .carousel-status{
    top: 10px !important;
  }
  .details-heading{
    font-size: 15px;
    margin: 0px;
    line-height: 2.0;
  }
  .pump-image{
    width: 587px;
    height: 438px;
  }
  .energy-image{
    width: 542px;
    height: 450px;
  }
  .filter-icon{
    float: right;
  }
  .description-cards{
    width: 270px;
    height: 150px;
    flex: 0 0 calc(20% - 20px); /* 5 columns in each row */
    margin: 10px; /* Adjust as needed */

  }
  .result-spinner{
    width: 50px !important;
    height: 50px !important;
    position: initial;
  }

  .p-progress-spinner-svg{
    width: 50px !important;
    height: 50px !important;
  }

  .table-gray{
    color: #81938f;
  }

  .no-table-gray{
    font-weight: 600;
  }

  .no-click{
    cursor: not-allowed !important;
  }
  .inline-table .p-datatable-table{
    display: inline-table;
  }

  .doppelfiltet-image{
    margin: 0 auto;
  }

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  width:84px;
  top: 12px;
}

.dropdown-content a {
  //color: white;
  padding: 12px 10px;
  text-decoration: none;
  display: block;
  //background-color: #000;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.table_input{
  width: 80px;
}
.update-channels{
  text-decoration: none;
}

.myLabel{
  font-size: 15px;
}
#svg-content{
  svg{
    margin-top: -60px
  }
}

.rectangle1 {
  width: 30px;
  height: 20px;
  background-color: yellow;
  border-radius: 5px;
  stroke: black;
  stroke-width: 2;
  border: 2px solid black;
  opacity: 0.1;
}

.rectangle2 {
  width: 30px;
  height: 20px;
  background-color: blue;
  border-radius: 5px;
  stroke: black;
  stroke-width: 2;
  border: 2px solid black;
  opacity: 0.1;
}

.rectangle3 {
  width: 30px;
  height: 20px;
  background-color: orange;
  border-radius: 5px;
  stroke: black;
  stroke-width: 2;
  border: 2px solid black;
  opacity: 0.1;
}

.function_table_input{
  width: 370px
}
.demo-card:hover{
    opacity: 0.4;
    cursor: pointer;
}
.demo-card{
  flex: 0 0 calc(20% - 20px); /* 5 columns in each row */
  margin: 10px; /* Adjust as needed */
}