
.ol-control {
  padding: 5px !important;

  button {
    font-size: 1.75em !important;
    background-color: rgb(255, 255, 255) !important;

    &:hover {
      font-size: 1.75em !important;
      background-color: rgb(250, 248, 248) !important;
    }
  }
}

.ol-scale-line {
  background-color: rgb(255, 255, 255) !important;
}

.ol-scale-line-inner {
  font-size: 1em !important;
}

.ol-map {
  min-width: 600px;
  min-height: 500px;
  margin: 50px;
  height: 500px;
  width: 1000px;
}

@font-face {
  font-family: 'Arial';
  src: local('Arial'), url('../fonts/ttf/Arial.ttf') format('truetype');
}

@font-face {
  font-family: 'Arial Black';
  src: local('Arial Black'), url('../fonts/ttf/Arial_Black.ttf') format('truetype');
}

@font-face {
  font-family: 'ESRI Geometric Symbols';
  src: local('ESRI Geometric Symbols'), url('../fonts/ttf/esri_3.ttf') format('truetype');
}

@font-face {
  font-family: 'ESRI Default Marker';
  src: local('ESRI Default Marker'), url('../fonts/ttf/esri_11.ttf') format('truetype');
}

@font-face {
  font-family: 'ESRI Public1';
  src: local('ESRI Public1'), url('../fonts/ttf/esri_29.ttf') format('truetype');
}

@font-face {
  font-family: 'ESRI Business';
  src: local('ESRI Business'), url('../fonts/ttf/esri_34.ttf') format('truetype');
}

@font-face {
  font-family: 'ESRI Enviro Hazard Analysis';
  src: local('ESRI Enviro Hazard Analysis'), url('../fonts/ttf/esri_801.ttf') format('truetype');
}

@font-face {
  font-family: 'ESRI ERS Infrastructures';
  src: local('ESRI ERS Infrastructures'), url('../fonts/ttf/esri_832.ttf') format('truetype');
}

@font-face {
  font-family: 'Wingdings';
  src: local('Wingdings'), url('../fonts/ttf/wingding.ttf') format('truetype');
}

@font-face {
  font-family: 'Wingdings 2';
  src: local('Wingdings 2'), url('../fonts/ttf/WINGDNG2.TTF') format('truetype');
}

@font-face {
  font-family: 'Wingdings 3';
  src: local('Wingdings 3'), url('../fonts/ttf/WINGDNG3.TTF') format('truetype');
}

.ol-popup {
  position: absolute;
  //background-color: white;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  top: inherit;
  bottom: 12px;
  left: -50px;
  right: inherit;
  min-width: 70px;

  &:after, &:before {
    top: 100%;
    bottom: inherit;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-top-color: white;
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
  }

  &:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
  }

  &.top-left {

    top: inherit;
    bottom: 12px;
    left: inherit;
    right: -50px;

    &:after {
      left: inherit;
      right: 48px;
      margin-left: inherit;
      margin-right: -10px;
    }

    &:before {
      left: inherit;
      right: 48px;
      margin-left: inherit;
      margin-right: -11px;
    }
  }

  &.bottom-right {

    top: 12px;
    bottom: inherit;
    left: -50px;
    right: inherit;

    &:after {
      left: 48px;
      right: inherit;
      top: -20px;
      transform: scaleY(-1);
      margin-left: -10px;
      margin-right: inherit;
    }

    &:before {
      left: 48px;
      right: inherit;
      top: -22px;
      transform: scaleY(-1);
      margin-left: -11px;
      margin-right: inherit;
    }
  }

  &.bottom-left {

    top: 12px;
    bottom: inherit;
    left: inherit;
    right: -50px;

    &:after {
      left: inherit;
      right: 48px;
      top: -20px;
      transform: scaleY(-1);
      margin-left: inherit;
      margin-right: -10px;
    }

    &:before {
      left: inherit;
      right: 48px;
      top: -22px;
      transform: scaleY(-1);
      margin-left: inherit;
      margin-right: -11px;
    }
  }
}

.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}

.ol-popup-closer:after {
  content: "✖";
}

.legendBackgroundImg {
  background-color: white;
}

.clippedImg {
  clip-path: inset(0 35 0 0);
  background-color: white;
}


.mapLegendParentDiv {
  // position: absolute;
  z-index: 10;
  // left: 60px;
  // top: -250px;
  // width: 350px;
  // height: 750px;
  //background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: auto;
}

.mapLegendDiv {
  margin-top: 0;
  //color: black !important;
  font-weight: bold;
  font-size: 18px;
}

.bookmarkBttn {
  display: none;
}

.bookmarkTitle:hover + .bookmarkBttn, .bookmarkBttn:hover {
  display: inline-block;
}

.overlayMeasurement{
  color:#000000;
  background-color: #ffffff;
}

