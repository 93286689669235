
.weather-dashboard-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-template-columns:  2fr;
    gap: 0px;
    margin: 0px 5px  5px 5px;  /* top,right,bottom,left .*/
    /*background-color: #C8DAE6*/
}

@media (max-width: 760px) {
    .weather-dashboard-grid{
        flex-direction: column;
        grid-template-columns: repeat(2,1fr);
    }
}

.p-timestamp {
    margin: 5px 0 !important;
    font-family: 'lato', sans-serif;
    font-weight: 300;
    font-size: 15px !important;
}

/************************************************** data-container **************************************************/
.data-container {
    height: 175px; /*change card length size */
    width: 245px; /*change card  width size */
    /* position: fixed; */
    overflow: hidden;
    cursor: pointer;  /*change mouse style when over element */
    transition: background-color 0.5s ease;
    /*box-shadow: 0px 0px 35px 1px rgba(50, 50, 50, 0.3);*/
    animation: appear 500ms ease-out forwards;
    margin: 10px;  /* margin around all cards. */
}

.weather-dashboard-grid .data-container {
    padding: 15px; /* padding around the words*/
    /*border: 10px solid #00003c;*/
    border-radius: 25px;  /*change corners of card container */
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.6s ease;
    box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.3);
    animation: appear 500ms ease-out forwards;
    text-align: left;
}

.weather-dashboard-grid .data-container:hover {
    background-color: rgba(0, 0, 0, 1);
    transform: scale(1.1); /* (110% zoom - Note: if zoom is too large, it goes outside of viewport) */
}

.weather-dashboard-grid .data-container .icon {
    position: absolute;
    top: 10px;
    right: 35px;
    font-size: 1.5em; /* make icons bigger */
    animation: appear 500ms ease-out backwards;
}

.weather-dashboard-grid .data-container p {
    margin: 5px 0;
    font-family: 'lato', sans-serif;
    font-weight: 300;
    font-size: 20px;
}

.data-container h4 {
    margin: 3px 0;
    font-size: 20px;
    font-weight: bold;
    font-family: Arial, sans-serif;
}

/********************************************** data-container-disabled **********************************************/
.data-container-disabled {
    height: 175px; /*change card length size */
    width: 245px; /*change card  width size */
    /* position: fixed; */
    overflow: hidden;
    cursor: default;  /*change mouse style when over element */
    margin: 10px;  /* margin around all cards. */
}

.weather-dashboard-grid .data-container-disabled {
    padding: 15px; /* padding around the words*/
    /*border: 10px solid #ccc5c5;*/
    border-radius: 25px;  /*change corners of card container */
    position: relative;
    overflow: hidden;
    /*box-shadow: 0px 0px 30px 2px rgba(50, 50, 50, 0.3);*/
    box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.3);
}

.weather-dashboard-grid .data-container-disabled .icon {
    position: absolute;
    top: 10px;
    right: 35px;
    font-size: 1.5em; /* make icons bigger */
    animation: appear 500ms ease-out backwards;
}

.weather-dashboard-grid .data-container-disabled p {
    margin: 5px 0;
    font-family: 'lato', sans-serif;
    font-weight: 300;
    font-size: 15px;
    color: darkred;
}

.data-container-disabled h4 {
    margin: 3px 0;
    font-size: 20px;
    font-weight: bold;
    font-family: Arial, sans-serif;
}